<template>
    <div>
        <h2>Calcul des intérêts dûs à la date du : {{endDate.toLocaleDateString('fr')}}</h2>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Année - semestre</th>
                    <th>Taux</th>
                    <th>Nombre de jour</th>
                    <th>Intérêts dûs</th>
                    <th>Cumul</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="line in completeTable" v-bind:key="line['year']">
                  <td>{{line['year']}}</td>
                  <td>{{(line['rates']*100).toFixed(2)}} %</td>
                  <td>{{line['days']}}</td>
                  <td>{{line['perYearInterests']}} € <span :key="line['rates']" @change="refreshPopper" class="popover-trigger" data-bs-trigger="hover focus" data-bs-toggle="popover" data-bs-placement="bottom"  data-bs-title="Détail du calcul" :data-bs-content="(line['computedBill']).toFixed(2) + ' &#10006; '+ (line['rates']*100).toFixed(2) +' % &#10006; ' + line['days'] +' &#10135; 365'">&#128161;</span></td>
                  <td>{{line['sum']}} €</td>
                </tr>
            </tbody>
        </table>
        <JsonExcel :data="Object.values(completeTable)">
            <button class="btn btn-info">Télécharger au format Excel</button>
        </JsonExcel>
    </div>
</template>

<script>
import JsonExcel from "vue-json-excel3";
import {Popover} from "bootstrap";

export default {
    name: "TableVue",
    props: ['completeTable', 'endDate'],
    components: {
        JsonExcel
    },
    computed: {
        currentDate() {
            return new Date().toLocaleDateString('fr-FR')
        },
    },
  methods: {
    refreshPopper() {
      return this.popper.update();
    }
  },
  mounted() {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    this.popper = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl))
  },
  updated() {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    this.popper = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl))
  }
}
</script>

<style scoped>
  .popover-trigger {
    cursor: help;
  }
</style>
