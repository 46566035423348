import Vuex from 'vuex'
import data from "@/data.json"

export default new Vuex.Store({
    state: data,
    mutations: {
        modifyRate(state, {year, sem, rate}) {
            console.log(rate)
            state.rates[year][sem] = rate
        }
    }
})
