<template>
    <form class="col-2">
        <h2>Saisie des informations du dossier</h2>
        <label class="form-label mt-2 border-top border-info" for="decision-date">Date de la décision :</label>
        <input class="form-control" name="decision-date" id="decision-date" type="date" :value=decisionDate v-on:input="decisionDate = $event.target.value">
        <label class="form-label mt-2 border-top border-info" for="bill">Montant de l'indemnisation :</label>
        <input class="form-control" name="bill" id="bill" type="number" :value="bill" v-on:input="bill = $event.target.value">
        <label class="form-label mt-2 border-top border-info" for="end-date">Date de fin du calcul d'intérêt :</label>
        <input class="form-control" name="end-date" id="end-date" type="date" :value=endDate v-on:input="endDate = $event.target.value">
      <div class="form-check form-switch mt-2 border-top border-info form-check-reverse">
        <label class="form-check-label" for="interests_capitalization">Capitalisation des intérêts</label>
        <input class="form-check-input" type="checkbox" role="switch" id="interests_capitalization" v-model="capitalization" v-on:change="capitalization = $event.target.checked">
      </div>
    </form>
    <div class="col-8">
        <TableVue :completeTable="completeTable" :endDate="new Date(endDate)"></TableVue>
    </div>
</template>

<script>
import TableVue from "@/components/TableVue.vue";
export default {
    name: "DataInput",
    components: {
            TableVue
        },
    data() {
        return {
            caseName: '',
            decisionDate: '2020-01-01',
            bill: 0,
            endDate: new Date().toISOString().split('T')[0],
          capitalization: false,
        }
    },
    computed:  {
        numberOfDaysEachYear() {
            let start = new Date(this.decisionDate)
            let now = (new Date(this.endDate))
          now.setDate(now.getDate() +1)
            let firstYear = start.getFullYear()
            let lastYear = now.getFullYear()
            let table = {}

          //Only one year
            if (firstYear === lastYear) {
              if (now < new Date(lastYear + "-07-01")) {
                table[lastYear] = {
                  s1: Math.ceil((now - start) / (1000 * 3600 * 24)),
                  s2: 0
                }
              } else {
                table[lastYear] = {
                  s1: Math.ceil((new Date(lastYear + "-07-01") - start) / (1000 * 3600 * 24)),
                  s2: Math.ceil((now - new Date(lastYear + "-07-01")) / (1000 * 3600 * 24))
                }
              }
              return table
            }

            // Two or more years
            if (start < new Date(firstYear + "-07-01")) {
              table[firstYear] = {
                  s1: Math.ceil((new Date(firstYear + "-07-01") - start) / (1000 * 3600 * 24)),
                  s2: Math.ceil((new Date(firstYear + 1 + "-01-01") - new Date(firstYear + "-07-01")) / (1000 * 3600 * 24))
              }
            } else {
              table[firstYear] = {
                  s1: 0,
                  s2: Math.ceil((new Date(firstYear + 1 + "-01-01") - start) / (1000 * 3600 * 24))
                }
            }
            for (let i = firstYear + 1; i < lastYear; i++) {
                table[i] = {
                    s1: Math.ceil((new Date(i + "-07-01") - new Date(i + "-01-01")) / (1000 * 3600 * 24)),
                    s2: Math.ceil((new Date(i + 1 + "-01-01") - new Date(i + "-07-01")) / (1000 * 3600 * 24))
                }
            }
            if (now < new Date(lastYear + "-07-01")) {
                table[lastYear] = {
                    s1: Math.ceil((now - new Date(lastYear + "-01-01")) / (1000 * 3600 * 24)),
                    s2: 0
                }
            } else {
                table[lastYear] = {
                    s1: Math.ceil((new Date(lastYear + "-07-01") - new Date(lastYear + "-01-01")) / (1000 * 3600 * 24)),
                    s2: Math.ceil((now - new Date(lastYear + "-07-01")) / (1000 * 3600 * 24))
                }
            }
            return table
        },
        completeTable() {
            let display = {}
            let sum = 0.00
          let computedBill = Number(this.bill)
            Object.keys(this.numberOfDaysEachYear).forEach(year => {
                if (this.$store.state.rates[year] !== undefined) {
                    if (this.numberOfDaysEachYear[year]["s1"] > 0) {
                      let interests = Number(this.$store.state.rates[year]["s1"] * computedBill * this.numberOfDaysEachYear[year]["s1"]/365)
                        sum = (Number(sum) + interests).toFixed(2)
                        display[year + " - Sem. 1"] = {
                            year: year + " - Sem. 1",
                            days: this.numberOfDaysEachYear[year]["s1"],
                            rates: (this.$store.state.rates[year]["s1"]),
                            perYearInterests: interests.toFixed(2),
                            sum: sum,
                          computedBill: computedBill,
                        }
                        if (this.capitalization) {
                          computedBill = Number(computedBill)+interests
                        }
                    }
                    if (this.numberOfDaysEachYear[year]["s2"] > 0) {
                      let interests = Number(this.$store.state.rates[year]["s2"] * computedBill * this.numberOfDaysEachYear[year]["s2"]/365)
                      sum = (Number(sum) + interests).toFixed(2)
                        display[year + " - Sem. 2"] = {
                            year: year + " - Sem. 2",
                            days: this.numberOfDaysEachYear[year]["s2"],
                            rates: (this.$store.state.rates[year]["s2"]),
                            perYearInterests: interests.toFixed(2),
                            sum: sum,
                          computedBill: computedBill,
                        }
                      if (this.capitalization) {
                        computedBill = Number(computedBill)+interests
                      }
                    }
                }
            })
            return display
        }
    },
}
</script>

<style scoped>

</style>
