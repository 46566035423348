<template>
 <div class="container">
  <div class="row">
   <div class="m-3 border-bottom">
    <h1>IK : Calculateur d'intérêt</h1>
   </div>
  </div>
  <div class="row m-5">
   <DataInput/>
   <div class="col-2">
    <InterestsRates/>
   </div>
  </div>
  <div class="row justify-content-center">
   <footer class="mt-4 col-5 alert alert-info">
    Créé par <a href="https://www.ezytech.fr">Ezytech</a>, disponible sous licence Apache 2.0
   </footer>
  </div>
 </div>
</template>

<script>
import DataInput from "@/components/DataInput.vue";
import InterestsRates from "@/components/InterestsRates.vue";

export default {
  name: 'App',
  components: {
   InterestsRates,
      DataInput,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
