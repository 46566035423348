<template>
    <h2>Modifications des taux</h2>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Période</th>
        <th scope="col">Taux</th>
      </tr>
    </thead>
    <tbody>
      <tr
          v-for="line in showRates"
          v-bind:key="line['year']"
      >
        <td>
          {{line['year'].split(' ')[0]}} <br>
          {{line['year'].split(' ')[1]}}
        </td>
        <td>
          <input class="inputRate" type="number" :value="(line['rate'] * 100).toFixed(2)" v-on:change="updateRates(line['year'], $event.target.value)"> %
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
 export default {
     name: 'InterestsRates',
   computed: {
       showRates() {
         let tmp = [];
         Object.keys(this.$store.state.rates).forEach(value => {
           tmp.push({
             year: value + ' S.1',
             rate: this.$store.state.rates[value]["s1"]
           })
           tmp.push({
             year: value + ' S.2',
             rate: this.$store.state.rates[value]["s2"]
           })
         })
         return tmp
       }
   },

   methods: {
       updateRates(year, rate) {
         let data = {
           year: year.slice(0,4),
           sem: "s"+year.substr(year.length -1),
           rate: rate/100
         }

         this.$emit('ratesUpdated', {year: year, rate: rate})
         this.$store.commit('modifyRate', data)
       }
   }
 }
</script>

<style scoped>
  table {
    width: 100%;
  }
  .inputRate {
    display: inline;
    width: 75%;
  }
</style>
